
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommunicationService } from 'src/services/communication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from 'config/app.config';
import { ArticleService } from 'src/services/article.service';
import { saveAs } from 'file-saver';
import { JournalService } from 'src/services/journal.service';
@Component({
  selector: 'app-menu',
  styleUrls: ['./menu.component.css'],
  templateUrl: './menu.component.html',
  providers: [AuthService, CookieService]
})

export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('logoutModal', { static: true }) public logoutModal: ModalDirective;
  @ViewChild('helpModal', { static: true }) public helpModal: ModalDirective;
  @ViewChild('scoreBoardModal', { static: true }) public scoreBoardModal: ModalDirective;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  adminAuthority = false;
  userName: any;
  displayName;
  modalRef: BsModalRef;
  // TO MAKE RESPONSIVE
  navbarOpen = false;
  isSuperAdmin: boolean = false;
  isUserLoggedIn: boolean;
  public allPanelReviewers = [];
  public groupData = [];
  public searchReviewer;
  public reviewerData;
  public totalAssignedTasks = 0;
  public totalPendingTasks = 0;
  public totalCompletedTasks = 0;
  public totalTimedoutTasks = 0;
  public uservalue = 0;
  public panelAuthority: boolean;
  public dashboardData;
  public totalAbstarcts = {};
  isChiefEditor;
  isJuryMember;
  isAdministrator;
  juryScores = [];
  constructor(public authService: AuthService, private route:ActivatedRoute,
    public router: Router, private articleService: ArticleService, private journalService:JournalService,
    public CookieService: CookieService, private formBuilder: FormBuilder,
    public communicationService: CommunicationService) {
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  public ngOnInit() {

    const helper = new JwtHelperService();
    this.isUserLoggedIn =  localStorage.getItem('token')  ? true : false;
    this.userName = localStorage.getItem('userName');
    this.displayName = localStorage.getItem('displayName');
    this.adminAuthority = (localStorage.getItem('adminAuthority') === 'true');
    const token = localStorage.getItem('token');
    if(token) {
      const decodedToken = helper.decodeToken(token);
      this.isChiefEditor = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_chiefeditor`) ? true : false);
      this.isAdministrator = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_administrator`) ? true : false);
      this.isJuryMember = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_jury_member`) ? true : false);
    }
    if (this.route.snapshot.queryParams['token']) {
      const token = this.route.snapshot.queryParams['token'];
      const taskId = this.route.snapshot.queryParams['taskId'];
      this.isUserLoggedIn = true;
      if (token && taskId) {
        if (!this.userName) {
          const data = {
            taskId,
            token
          }
          this.authService.loginUsingToken(data).subscribe((res) => {
            if (res.body.token) {
              const decodedToken = helper.decodeToken(res.body.token);
              this.isChiefEditor = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_chiefeditor`) ? true : false);
              this.isAdministrator = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_administrator`) ? true : false);
              this.isJuryMember = decodedToken.data.roles.find(role => (role == `GROUP_jrnl_${AppConfig.JOURNAL_ID}_jury_member`) ? true : false);
              this.adminAuthority = decodedToken.data.isAdmin;
              this.userName = decodedToken.data.firstName;
              this.displayName = `${decodedToken.data.displayName}`;
            }
          });
        }
      }
    }
    
  }


  goToAdminConferenceManagement() {
    this.router.navigate(['/admin/conference/management']);
  }
  gotoEmailTemplateManagement() {
    this.router.navigate(['/admin/email/management']);

  }
  gotoHelpContentManagement() {
    this.router.navigate(['/admin/helpcontent/management']);
  }
  goToJournals() {
    this.router.navigate(['/home/conferences']);
  }
  goToAdminUsers() {
    this.router.navigate(['/admin/users']);
  }
  onClickHome() {
    if (this.isUserLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['']);
    }
  }
  onClickLogo() {
    if (this.isUserLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['']);
    }

  }
  gotoAdminClients() {
    this.router.navigate(['/admin/clients']);
  }
  goToAdminReviewers() {
    this.router.navigate(['/admin/reviewers']);
  }
  gotoAdminGroups() {
    this.router.navigate(['/admin/groups']);
  }
  goToSuperAdminHome() {
    this.router.navigate(['/admin/home']);
  }


  confirmLogoutModal() {
    this.logoutModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.logoutModal.show();
  }
  goToRegister() {
    this.router.navigate(['/register']);
  }
  public goToAllTasks() {
    this.router.navigate(['mytasks']);
  }
  logoutConfirm() {
    this.authService.logout().subscribe((res) => {     
      this.communicationService.onlogoutUser();  
      sessionStorage.removeItem('abstractData');
    });
    this.logoutModal.hide();
    this.router.navigate(['']);
    this.CookieService.deleteAll();
    sessionStorage.clear();
    localStorage.clear();
    this.CookieService.deleteAll();
  }
  goToAbout(link) {
    this.router.navigate([link]);
  }
  goToContact(link) {
    this.router.navigate([link]);
  }
  onChangePassword(link) {
    this.router.navigate([link]);
  }
  onUpdateProfile(link) {
    this.router.navigate([link]);
  }
  goToAllSubmission() {
    this.router.navigate(['/admin/submissions']);
  }
  goToJuryEvaluation() {
    this.router.navigate(['/admin/jury']);
  }
  public goToAdminEmailTemplate() {
    this.router.navigate(['/admin/emailTemplate/management']);
  }
  public onClickHelp() {
    this.helpModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.helpModal.show();
  }
  public onClickInstructions() {
    const fileUrl = '/assets/Reviewer Instruction.pdf';
    window.open(fileUrl, '_blank');
  }
  public ngOnDestroy() {
    if (this.helpModal) {
      this.helpModal.hide();
    }
    if (this.logoutModal) {
      this.logoutModal.hide();
    }
  }


  openJuryScoreBoard() {
    this.getReviewScoreBoard();
    this.scoreBoardModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.scoreBoardModal.show();
  }
  getReviewScoreBoard() {
    this.juryScores = [];
    this.articleService.getReviewScoreBoard().subscribe(res => {
      if(res.body){
        this.juryScores = res.body;
      }
    });
  }
  onRefreshJuryScore() {
    this.getReviewScoreBoard();
  }
  OnDownloadExcelAsSummary() {
    const downloadType= 'summary';
    this.getReviewScoreBoardAsExcel(downloadType);
  }
  OnDownloadExcelAsRaw() {
    const downloadType= 'raw';
    this.getReviewScoreBoardAsExcel(downloadType);
  }
  getReviewScoreBoardAsExcel(downloadType) {
    let fileName;
    if(downloadType == 'summary') {
      fileName = 'review-scores-summary.xlsx';
    } else {
      fileName = 'review-scores.xlsx';
    }
    this.articleService.getReviewScoreBoardAsExcel(downloadType).subscribe(res=>{
      if(res.body) {
        const mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([res.body], {
          type: mediaType,
        });
        saveAs(blob, `${fileName}`);
      }
    })
  }
  onSummarizeReviewScores() {
    this.articleService.summarizeReviewScores().subscribe(res => {
      if(res.body) {
        this.getReviewScoreBoard();
      }
    });
   }



}

